<template>
  <section id="contact-section">
    <div class="section-body container">
      <div class="section-title">
        <div class="title-content">
          <span class="heading-2">Unsere</span>
          <span class="heading-2 text-1">Kontakte</span>
        </div>
        <div class="title-description paragraph">
          <div class="contact-item" v-for="(item, i) in contactItems" :key="i">
            <SvgIcon :name="item.icon" />
            <a class="paragraph normal"
              :href="item.type === 'phone' ? `callto:${item.description}` : (item.type === 'mail' ? `mailto:${item.description}` : 'https://www.google.de/maps/search/Erlenstrasse+11,+32549+%D0%91%D0%B0%D0%B4-%D0%AD%D0%B9%D0%BD%D1%85%D0%B0%D1%83%D0%B7%D0%B5%D0%BD-%D0%91%D0%B0%D0%B4+%D0%AD%D0%BA%D1%81%D0%B5%D0%BD/@52.2325312,8.8130651,16.5z?entry=ttu')">{{
                item.description
              }}</a>
          </div>
        </div>
      </div>
      <ContactForm btnText="Telefonat anfordern" />
    </div>
  </section>
</template>

<script setup>
import ContactForm from '~/components/welcome/ContactForm'
import SvgIcon from '~/components/ui/SvgIcon.vue';

const contactItems = ref([
  { icon: 'map', description: 'Naggertstraße 52, 33729 Bielefeld', type: 'map' },
  { icon: 'mail', description: 'info@dobobau.de', type: 'mail' },
  { icon: 'mail', description: 'dobo.bau.bielefeld@gmail.com', type: 'mail' },
  { icon: 'phone', description: '01 57 597 40 593', type: 'phone' }
])
</script>

<style lang="scss" scoped>
#contact-section {
  background: white;
  position: relative;
  padding-top: 96px;

  .section-body {
    display: flex;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 76.48%, #FFFFFF 100%), linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%, #FFFFFF 100%), linear-gradient(180deg, #FFFFFF 23.94%, rgba(255, 255, 255, 0) 100%), url('/img/welcome/contact.svg');
    background-position: center;
    padding-top: 96px;
    padding-bottom: 158px;


    box-sizing: border-box;

    background-clip: padding-box;
    border-top: solid 1px transparent;

    .section-title {
      display: flex;
      flex-direction: column;
      gap: 24px;
      // justify-content: center;
      // margin-top: 64px;

      .title-content {
        display: flex;
        gap: 12px;
        --_text-color-1: #8a8776;

        .text-1 {
          color: var(--_text-color-1);
        }
      }

      .title-description {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .contact-item {
          display: flex;
          align-items: center;
          gap: 10px;

          .paragraph {
            text-decoration: none;

          }
        }
      }
    }

    :deep(.contact-form) {
      width: 412px;
      // margin-top: 58px;

      @media(max-width:991px) {
        width: 329px;
        margin-top: 0;
        max-width: calc(100vw - 32px);
      }
    }

  }

  &::before {
    content: '';
    position: absolute;
    width: 120%;
    height: 155px;
    left: -50.27px;
    top: -80px;
    background: #FCFBF5;
    transform: rotate(-3.17deg);
  }

  &::after {
    content: '';
    position: absolute;
    width: 120%;
    height: 2px;
    left: -50.27px;
    top: 75px;
    transform: rotate(-3.17deg);
    background: linear-gradient(256.45deg, #FCFBF5 28.33%, #C1BFB0 97.99%);
  }

  @media(max-width:991px) {
    .section-body {
      flex-direction: column;
      align-items: center;
      gap: 136.6px;
      padding-top: 80px;
      padding-bottom: 32px;

      .section-title {


        span {
          text-align: center
        }

        .title-content {
          flex-wrap: wrap;
          justify-content: center;
        }

        .title-description {
          .contact-item {
            justify-content: center;

            a {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>